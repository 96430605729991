<template>
  <div v-if="isPermission && isEditMember">
    <CCard>
      <CCardBody>
        <div class="d-flex align-items-center">
          <div style="flex: 1;">
            <CButton id="addressListBackButton" block color="light" @click="goback()" class="align-items-center" style="width:30%">
              <span><i class="fi fi-rr-arrow-left mr-2" style="position: relative; top: 2px;"></i></span>{{ $t('back') }}
            </CButton>
          </div>
          <router-link id="addressListAddAddressLink" v-if="isEditMember" style="border:none; text-decoration: none; flex: 1; padding-left: 8px; "
            :to="`/member/${objectId}/address/add`">
            <CButton id="addressListAddAddressButton" color="success" block style="width:30%" class="float-right">{{ $t('addAddress') }}</CButton>
          </router-link>
        </div>


        <div class="d-flex mt-5">
          <h4 class="col-10 font-weight-bold">
            {{ $t('addressLists') }}
          </h4>
        </div>
        <hr v-if="data.length > 0" />
        <CDataTable  id="addressListTable" class="mt-3" v-if="data.length === 0">
          <template #no-items-view>
            <div class="text-center my-5">
              <h4 style="color: #ced2d8;">
                {{ $t('noItem') }}
              </h4>
            </div>
          </template>
        </CDataTable>
        <ul id="addressListGroup" class="list-group list-group-flush pb-2" color="light" v-else-if="data.length > 0">
          <li class="list-group-item pl-0 pr-0" v-for="item in data" :key="item.objectId">
            <table style="width:100%">
              <tr>
                <td>
                  <div class="d-flex">
                    <h6 id="addressListName" class="font-weight-bold">
                      {{ item.firstname || item.name || '' }} {{ item.lastname || '' }}
                    </h6>
                    <h6 id="addressListPhoneNumber" class="text-grey pl-2">
                      {{ item.phoneNumber }}
                    </h6>
                  </div>
                  <span id="addressListAddress">
                    {{
                      [item.address, 
                      typeof item.subDistrict === 'string' ? item.subDistrict : item.subDistrict?.name, 
                      item.district?.name || item.district, 
                      item.province?.name || item.province, 
                      item.postalCode]
                      .filter(part => part && part.trim() !== '')
                      .join(', ')
                    }}
                  </span>
                  <CBadge id="addressListDefaultBadge" color="success" v-if="item.isDefault === true" class="p-1">
                    {{ $t('default') }}
                  </CBadge>
                  &nbsp;&nbsp;
                  <CBadge id="addressListTaxBadge" color="success" v-if="item.isTaxAddress === true" class="p-1">
                    {{ $t('taxAddress') }}
                  </CBadge>
                  &nbsp;&nbsp;
                  <CBadge id="addressListShippingBadge" color="success" v-if="item.isDeliveryAddress === true" class="p-1">
                    {{ $t('shippingAddress') }}
                  </CBadge>
                </td>
                <td style="width:15%" class="text-right pb-0" v-if="isEditMember">
                  <router-link id="addressListEditLink" style="text-decoration:none" :to="`/member/${objectId}/address/${item.objectId}`">
                    <p id="addressListEditText" class="text-muted">
                      <i class="fi fi-rr-edit"></i> {{ $t('edit') }}
                    </p>
                  </router-link>
                  <CButton id="addressListDefaultButton" v-if="item.isDefault === true || loadingButton === true" class="btn-success btn-sm"
                    style="font-size:12px" disabled>
                    {{ $t('setAsDefault') }}
                  </CButton>
                  <CButton id="addressListSetDefaultButton" v-else class="btn-outline-success btn-sm" style="font-size:12px"
                    @click="setDefaultAddress(item.objectId)">
                    {{ $t('setAsDefault') }}
                  </CButton>
                </td>
              </tr>
            </table>
          </li>
        </ul>
      </CCardBody>
    </CCard>
  </div>
  <div v-else id="addressListNoPermission">
    <access-data></access-data>
  </div>
</template>

<script>
import pos from '@/services/local'
import permis from '@/util/permission'

export default {
  data() {
    return {
      data: [],
      loadingButton: false,
    }
  },
  computed: {
    objectId() {
      return this.$route.params.objectId
    },
    isPermission() {
      return permis.findPermissionRead('member', '/member/data')
    },
    isEditMember() {
      return permis.findPermissionEdit('member', '/member/data')
    },
  },
  created() {
    this.getAddressByMemberObjectId()
  },
  methods: {
    getAddressByMemberObjectId() {
      const objectId = this.objectId
      let url = '/api/v1.0/address/getbymember/' + objectId

      pos({
        method: 'get',
        url: url,
      })
        .then((res) => {
          this.data = res.data.data
        })
        .catch((error) => {
          console.log(error)
        })
    },
    async setDefaultAddress(newObjectId) {
      let findIsDefault = this.data.find((i) => i.isDefault === true)

      if (findIsDefault) {
        await this.updatedata(findIsDefault.objectId, false)
      }

      await this.updatedata(newObjectId, true).then(() => {
        setTimeout(() => {
          this.getAddressByMemberObjectId()
        }, 1000)
      })
    },
    async updatedata(addressObjectId, isDefault) {
      this.loadingButton = true
      let data = {
        objectId: addressObjectId,
        isDefault: isDefault,
      }

      pos({
        method: 'post',
        url: '/api/v1.0/address/update',
        data: data,
      })
        .then(() => {
          this.loadingButton = false
        })
        .catch((error) => {
          console.log(error)
        })
    },
    goback() {
      this.$router.push('/member/' + this.objectId + '/editmember')
    },
  },
}
</script>
